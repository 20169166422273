<template>
    <div class="product_history_block">
        <div class="product_history_block_inner">
            <div 
                class="btn btn-primary btn-standart" style="text-align: center;" 
                v-html="activeParentProduct ? 'Parent product: <br/>' + parentProducts.find(p=>p.id==activeParentProduct).title : product.title"
            ></div>

            <div v-for="(item,index) in activeProductHistory" :key="index">
                <!-- <div class="mobile-devider"></div> -->
                <!-- <div class="mobile-history__block">
                    <div class="mobile-history__data mobile-history__data--right">
                        <div>{{item.date}}</div>
                    </div>
                    <div class="mobile-history__mark-wrapper">
                        <inline-svg :src="require('@/assets/img/star.svg')" />
                    </div>
                    <div class="mobile-history__data">
                        <div>{{ item.text }}</div>
                    </div>
                </div> -->
                <div style="margin: 20px 0;font-size: 14px;">
                    <template v-for="(dateImtem, dateIndex) in item.date">
                        <span :key="dateIndex">{{ dateIndex!== 0 ? " - " : '' }}{{ new Date(dateImtem).toLocaleDateString() }}</span>
                    </template> — {{ item.text }}
                </div>
            </div>

            <!-- <div v-if="!(isParentProduct ? parentProductHistory : productHistory).length">
                <div class="mobile-devider"></div>
                <div class="mobile-history__block">
                    <div class="mobile-history__data" style="text-align: center;">
                        <div>History is empty, check back later</div>
                    </div>
                </div>
            </div> -->
            <template v-if="!activeParentProduct">
                <div v-for="p in parentProducts" :key="p.id" class="history_parent_product_wrpr">
                    <div class="btn btn-primary btn-standart btn-hover" @click="activeParentProduct=p.id">{{p.title}}</div>
                </div>
            </template>
            <button v-if="activeParentProduct" @click="activeParentProduct = null" type="button" class="btn btn-w-shadow btn-standart-2 mt-40" style="background: #fff;">
                <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)" />
                {{ $translate('back') }}
            </button>
        </div>
    </div>
</template>
<script>
import { API } from 'aws-amplify';
import {
    listCustomReports,
    listFarmActions,
    getProduct,
    getDispatch,
    getCompany
} from '@/graphql/queries';
export default {
    props: ['productID', 'companyID', 'product', 'company', 'dispatchReceiveDate'],
    data(){
        return {
            mainProductHistory:[],
            parentProducts:[],
            activeParentProduct:null,
            singleDispatch: null,
        }
    },
    computed:{
        activeProductHistory(){
            if(this.activeParentProduct){
                return this.parentProducts.find(p=>p.id==this.activeParentProduct)?.history||[]
            }else{
                function compare( a, b ) {
                    if (Date.parse(a.date) > Date.parse(b.date)){
                        return -1;
                    }
                    if (Date.parse(a.date) < Date.parse(b.date)){
                        return 1;
                    }
                    return 0;
                }
                return this.mainProductHistory.sort(compare)
            }
        },
        paddocks(){
            return this.$store.state.paddocks;
        },
    },
    methods:{
        async getParentProduct(id){
            const product = await API.graphql({
                query: getProduct,
                variables: {
                    id: id,

                },
            }).then((res) => res.data.getProduct);
            
            // this.parentProduct=product
            // let parentProductHistory=await this.getProductHistory(product.id)
            // parentProductHistory.sort((a,b)=>{
            //     let aDate=new Date(a.createdAt)
            //     let bDate=new Date(b.createdAt)
            //     return aDate.getTime()-bDate
            // })
            product.history=await this.getProductHistory(product.id,product)
            return product
        },
        toISO(date){

        },
        convertDate(dateNumber){
            function numbNoZero(numb){
                if(String(numb).length < 2){
                    return '0'+String(numb)
                } else {
                    return numb;
                }
            }

            let date = new Date(dateNumber);

            let returnedDate = `${numbNoZero(date.getDate())}.${numbNoZero(date.getMonth())}.${date.getFullYear()}`

            if(returnedDate.includes('Nan')){
                return 'Date not found'
            }

            return returnedDate;
        },
        transformDate(date,dropDate){
            if(!date) return '-';

            const shortMonths = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ]
            const shortDays = [
                'Sun','Mon','Tue','Wed','Thu','Fri','Sat',
            ]
            return  `${shortDays[date.getDay()]}, ${date.getDate()} ${shortMonths[date.getMonth()]} ${date.getFullYear()} ${("0"+date.getHours()).slice(-2)}:${("0"+date.getMinutes()).slice(-2)}:${("0"+date.getSeconds()).slice(-2)} GMT`
        },

        async getProductHistory(id,product){
            let history=[]
            // let manufacturing_date = product.manufacturing_date ? new Date([product.manufacturing_date.split('.')[1],product.manufacturing_date.split('.')[0],product.manufacturing_date.split('.')[2]].join('.')) : null;
            
            // history.push({ date: this.convertDate(manufacturing_date), text: product.title })
            
            if(product.dispatches && product.dispatches[0]){
                let dispatch = await API.graphql({
                    query: getDispatch,
                    variables: {
                        id: product.dispatches[0],
                    }
                }).then(res => res.data.getDispatch);

                this.singleDispatch = dispatch;
                console.log('dispatch',dispatch);
                let mainCompanyStatus = dispatch.companiesStatus.find(c => c.type === 'outcoming')
                const mainCompany = await API.graphql({
                    query: getCompany,
                    variables: {
                        id: mainCompanyStatus.id,
                    },
                }).then((res) => res.data.getCompany);

                let otherCompanyStatuses=dispatch.companiesStatus.filter(c=>c.type=='incoming')
                console.log('otherCompanyStatuses:', otherCompanyStatuses);
                for(let c of otherCompanyStatuses){
                    console.log('cid',c.id);
                    const cCompany = await API.graphql({
                        query: getCompany,
                        variables: {
                            id: c.id,
                        },
                    }).then((res) => res.data.getCompany);
                    if(c.incoming_date){
                        history.push({
                            // this.convertDate(new Date(+c.incoming_date))
                            date: [new Date(+c.incoming_date)],
                            text:`Dispatch of the product from company "${mainCompany.company_name}" to company "${cCompany.company_name}"`
                        })
                    }
                    if(c.receive_date){
                        history.push({
                            // this.convertDate(new Date(+c.receive_date))
                            date: [new Date(+c.receive_date)],
                            text:`Dispatch received for company "${cCompany.company_name}" from company "${mainCompany.company_name}"`
                        })
                    }
                    if(c.completed_date){
                        history.push({
                            date: [new Date(c.completed_date)],
                            text:`Completed Dispatch for company "${cCompany.name}" from company ${mainCompany.name}`
                        })
                    }
                }   
            }

            if(this.product.qr_settings.actions_info){
                let farmActions = await API.graphql({
                    query: listFarmActions,
                }).then(res => res.data.listFarmActions.items);
                let filteredFarmActions = [];
                
                this.product.paddocks.forEach(paddockItem => {
                    let findedAction = farmActions.find(item => item.related.map(item2 => item2.includes('-landregister-index-') ? item2.split('-landregister-index-')[0] : item2).includes(paddockItem));
                    // findedAction = farmActions.find(item => item.related.includes(paddockItem));

                    if((!filteredFarmActions.length && findedAction && findedAction.visible_on_qrcode) || (findedAction && !filteredFarmActions.map(item => item.id).includes(findedAction.id) && findedAction.visible_on_qrcode)){
                        filteredFarmActions.push(findedAction);
                    }
                });

                console.log('filteredFarmActions:', filteredFarmActions);

                filteredFarmActions.forEach(a=>{
                    let date = new Date(a.createdAt);
                    let findedPaddock = this.paddocks.find(item => a.related.includes(item.id));

                    history.push({
                        // this.convertDate(date)
                        date: [new Date(date)],
                        text: findedPaddock ? `${a.name_of_the_operation} in the ${findedPaddock.basic_info.name}` : a.name_of_the_operation
                    })
                })
            } else {
                console.log('---===Actions info hidden===---');
            }

            if(this.product.qr_settings.datetime_creation && this.product.manufacturing_date){
                var dateBuffer = this.product.manufacturing_date.split('.');
                console.log('DATE:', dateBuffer, [new Date(dateBuffer[2], parseInt(dateBuffer[1]) - 1, dateBuffer[0])]);
                history.push({
                    // this.convertDate().toUTCString()
                    date: [new Date(dateBuffer[2], parseInt(dateBuffer[1]) - 1, dateBuffer[0])],
                    text: 'Manufacturing Date'
                })
            }

            // if(this.product.qr_settings.datetime_receive && this.dispatchReceiveDate){
            //     history.push({
            //         date: new Date(+this.dispatchReceiveDate).toUTCString(),
            //         text: 'Dispatch in status completed'
            //     })
            // }

            if(
                this.product.qr_settings.rules_business_conditions &&
                this.singleDispatch &&
                this.singleDispatch.contract_conditions.length
            ){
                this.singleDispatch.contract_conditions.filter(itemF => itemF.condition_type === "business").forEach(item => {
                    if(item.date && item.time){
                        history.push({
                            date: [new Date(+item.date.split('.')[2], +item.date.split('.')[1], +item.date.split('.')[0], +item.time.split(':')[0], +item.time.split(':')[1], 0, 0)],
                            text: `Business condition ${item.type.split('_').join(' ')} ${item.select_batch_id ? ', batch: '+item.select_batch_id : ''}`
                        })
                    }
                });
            }

            if(
                this.product.qr_settings.smart_conditions &&
                this.singleDispatch &&
                this.singleDispatch.contract_conditions
            ){
                this.singleDispatch.contract_conditions.filter(itemF => itemF.condition_type === "smart").forEach(item => {
                    if(item.from_date && item.from_time && item.to_date && item.to_time){
                        // let fromDate = this.convertDate(new Date(+item.from_date.split('.')[2], +item.from_date.split('.')[1], +item.from_date.split('.')[0], +item.from_time.split(':')[0], +item.from_time.split(':')[1], 0, 0).toUTCString());
                        // let toDate = this.convertDate(new Date(+item.to_date.split('.')[2], +item.to_date.split('.')[1], +item.to_date.split('.')[0], +item.to_time.split(':')[0], +item.to_time.split(':')[1], 0, 0).toUTCString());

                        let fromDate = new Date(`${item.from_date.split('.')[1]}-${item.from_date.split('.')[0]}-${item.from_date.split('.')[2]}`);
                        let toDate = new Date(`${item.to_date.split('.')[1]}-${item.to_date.split('.')[0]}-${item.to_date.split('.')[2]}`);

                        if(item.parameter_obeserved && item.operator && item.value) {
                            history.push({
                                date: [new Date(fromDate),new Date(toDate)],
                                text: `Smart condition, ${item.parameter_obeserved} ${item.operator.split('_').join(' ')} ${item.value}${item.select_batch_id ? ', batch: '+item.select_batch_id : ''}`
                            })
                        }
                    }
                });
            }

            if(
                this.product.qr_settings.datetime_return &&
                this.singleDispatch &&
                this.singleDispatch.companiesStatus.length
            ){
                let findDateTimeReturn = this.singleDispatch.companiesStatus.find(item => item.return_date && item.return_date !== null);
                if(findDateTimeReturn){
                    history.push({
                        date: [new Date(+findDateTimeReturn.return_date)],
                        text: `Dispatch in status "${findDateTimeReturn.status}"`
                    })
                }
            }

            history.sort((a, b) => { return new Date(a.date[0]).getTime() - new Date(b.date[0]).getTime() })

            return history.reverse()

            // console.log("prid",this.productID);
            // const filter = {
            //     type: {
            //         eq: 'custom'
            //     },
            //     product: {
            //         eq: id
            //     }
            // }
            // const customReports = await API.graphql({
            //     query: listCustomReports,
            //     variables: {
            //         filter
            //     }
            // }).then(res => res.data.listCustomReports.items)
            // console.log('s',customReports,id);
            // let customReportsTransform=customReports.map(r=>{
            //     let date=new Date(r.createdAt)
            //     const shortMonths = [
            //         'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            //     ]
            //     const shortDays = [
            //         'Sun','Mon','Tue','Wed','Thu','Fri','Sat',
            //     ]
            //     r.date = `${shortDays[date.getDay()]}, ${date.getDate()} ${shortMonths[date.getMonth()]} ${date.getFullYear()} ${("0"+date.getHours()).slice(-2)}:${("0"+date.getMinutes()).slice(-2)}:${("0"+date.getSeconds()).slice(-2)} GMT`
            //     return r
            // })
            // return customReportsTransform
        }
    },


    async created(){
        this.mainProductHistory=await this.getProductHistory(this.productID,this.product)

        let parentProducts = this.product.parent_products.map(async p=>{
            return await this.getParentProduct(p.id)
        })
        await Promise.all(parentProducts).then(res=>this.parentProducts=res)
    }
}
</script>

<style scoped>
.product_history_block {
    position: relative;
}
.product_history_block:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: 100%;
    /* background: #DDE2E4; */
    background: transparent;
    z-index: 1;
}
.product_history_block_inner {
    z-index: 2;
    position: relative;
}
.btn-standart, .btn-standart-2 {
    width: 100%;
    min-height: 50px;
    height: initial;
}
.mobile-devider {
    background-color: var(--text-secondery);
    height: 40px;
    width: 1px;
    margin: 0 auto;
}
.mobile-history__block {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    margin: 20px 0;
}
.mobile-history__data {
    width: 131px;
    font-size: 14px;
}
.mobile-history__data--right {
    text-align: right;
}
.mobile-history__mark-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1FBFF;
    margin: 0 12px;
    border-radius: 10px;
}
.mobile-history__mark {
    width: 17px;
    height: 18px;
}
.mt-40 {
    margin-top: 40px;
}
.btn-hover:hover {
    cursor: pointer;
}
</style>