export default [
        'location',
        'history_of_company',
        'media',
        'documentation',
        'year_of_foundation',
        'production_per_year',
        'production_area',
        'soils',
        'cellar_work',
        'productivity',
        'certifications',
        'philosophy',
        'devices',
        'datetime_creation',
        'rules_business_conditions',
        'datetime_dispatch',
        'datetime_receive',
        'datetime_return',
        'transaction_history',
        'device_rule_log',
        'basic_info',
        'parent_products',
        'list_of_dispatches',
        'linked_IOT_devices',
        'smart_conditions',
]