<template>
    <div class="mobile-content" v-if="!loading">
        <header class="mobile-content__header">
            <div class="logo">
                <!-- <img class="mobile-logo__img" src="@/assets/img/mobile-logo-icon.svg" alt="Enismaro"> -->
                <img class="mobile-logo__img" src="@/assets/img/logo-icon.svg" alt="Enismaro" style="max-width: 32px;">
                <!-- src="@/assets/img/logo-text.svg" alt="Enismaro" -->
                <div class="heading-page-h1" style="font-weight: bold;font-size: 19px;">
                    <!-- {{ headerTitle }} -->{{ $translate('enismaro-food&wine-traceability') }}
                </div>
			</div>
        </header>
        <div class="mobile-wrapper__main" v-if="!loading && !product">
            <main class="mobile-content__body">
                <div class="tab-content">
				    <div id="product" class="tab-content__item" :class="isActiveTab('product')">
                        <h2 class="mobile__title" style="margin-top: 0; line-height: normal;">Product not found! <br> The product may have been removed</h2>
                    </div>
                </div>
            </main>
        </div>
        <div class="mobile-wrapper__main" v-if="product">
            <main class="mobile-content__body">
                <div class="tab-content">
				    <div id="product" class="tab-content__item" :class="isActiveTab('product')" :key="keyUpdate">
                        <h2 class="mobile__title" style="margin-top: 0;" v-if="!loading && !product">Product Not Found</h2>
                        <h2 class="mobile__title" style="margin-top: 0;">{{ product.title }}</h2>
                        <ul class="mobile__list">
                            <li class="mobile__list-item">
                                <div class="mobile__list-title">{{ $translate('quantity/units') }}:</div>
                                <div class="mobile__list-description">
                                    {{ product.quantity }}/{{ product.units }}
                                </div>
                            </li>
                            <li class="mobile__list-item">
                                <div class="mobile__list-title">{{ $translate('producer') }}:</div>
                                <div class="mobile__list-description">
                                    {{ company.company_name }}
                                </div>
                            </li>
                        </ul>

                        <img class="mobile__img" :src="m.url" alt="Product" v-for="m in productMedia" :key="m.id+'-images'" style="margin: 20px 0;">

                        <ul class="mobile__list">
                            <!-- <li class="mobile__list-item">
                                <div class="mobile__list-title">Quantity/Unit:</div>
                                <div class="mobile__list-description">
                                    {{ product.quantity }}/{{ product.units }}
                                </div>
                            </li>
                            <li class="mobile__list-item">
                                <div class="mobile__list-title">Producer:</div>
                                <div class="mobile__list-description">
                                    {{ company.company_name }}
                                </div>
                            </li> -->
                            <li class="mobile__list-item">
                                <div class="mobile__list-title">{{ $translate('batch-id') }}:</div>
                                <div class="mobile__list-description">
                                    {{ product.batch_id }}
                                </div>
                            </li>
                            <li class="mobile__list-item">
                                <div class="mobile__list-title">{{ $translate('description') }}:</div>
                                <div class="mobile__list-description">
                                    {{ product.description }}
                                </div>
                            </li>
                            <li class="mobile__list-item" v-if="!isSpecial">
                                <div class="mobile__list-title">{{ $translate('expiry-date') }}:</div>
                                <div class="mobile__list-description">
                                    {{product.expiry_date || "-" }}
                                </div>
                            </li>
                            <li class="mobile__list-item" v-if="!isSpecial">
                                <div class="mobile__list-title">{{ $translate('place-of-origin') }}:</div>
                                <div class="mobile__list-description">
                                    <a
                                        :href="`https://www.google.com/maps/search/+${ product.location && product.location.address ? product.location.address : locationFromPaddock ? locationFromPaddock.address : '-' }/?entry=ttu`" target="_blank"
                                        style="text-decoration: underline;color: #00283A;"
                                    >
                                        {{ product.location && product.location.address ? product.location.address : locationFromPaddock ? locationFromPaddock.address : "-" }}
                                    </a>
                                </div>
                            </li>
                            <li class="mobile__list-item" v-else>
                                <div class="mobile__list-title">{{ $translate('place-of-origin') }}:</div>
                                <div class="mobile__list-description">
                                    Lampedusa, Italy
                                </div>
                            </li>
                            <li class="mobile__list-item" v-if="product.location && product.location.address">
                                <div class="mobile__list-title">{{ $translate('location') }}:</div>
                                <div class="mobile__list-description">
                                    {{ product.location.address.split(',').reverse().join(', ') }}
                                </div>
                            </li>
                            <!-- <li class="mobile__list-item" v-if="!isSpecial">
                                <div class="mobile__list-title">{{ $translate('device-data') }}:</div>
                                <div class="mobile__list-description">
                                    {{ product.devices.items[0] ? product.devices.items[0].name : '-' }}
                                </div>
                            </li> -->
                            <li class="mobile__list-item" v-if="!isSpecial">
                                <div class="mobile__list-title">{{ $translate('serial') }}:</div>
                                <div class="mobile__list-description">
                                    {{ product.serial_number }}
                                </div>
                            </li>
                            <li class="mobile__list-item" v-if="false"> 
                                <div class="mobile__list-title">{{ $translate('sold-by') }}:</div>
                                <div class="mobile__list-description">
                                    01.02.2021 <!-- ??? -->
                                </div>
                            </li>
                        </ul>
                        <div v-if="false && product.qr_settings.linked_IOT_devices && product.devices.items.length" :key="devicesKeyUpdate+'-devices-group'">
                            <div class="flex items-center mt-15" style="justify-content: space-between;">
                                <div class="mobile__list-title m-0">{{ $translate('linked-iot-devices') }}:</div>
                                <button 
                                    class="btn btn-w-shadow btn-standart-2" :class="{ processing }"
                                    @click.prevent="refreshData" v-if="refreshData !== null" 
                                    style="max-width: 40px;height: 40px;padding: 5px;"
                                    title="Refresh devices data"
                                >
                                    <inline-svg :src="require(`@/assets/img/Arrow-Rotate.svg`)" />
                                </button>
                            </div>

                            <template v-for="(item, deviceItemIndex) in product.devices.items">
                                <div 
                                    class="parent_product_box mt-15" :key="item.id" :class="[{'less_paddings':item.category}, { 'open':devicesDropdownOpen[deviceItemIndex] }]"
                                    @click="() => { devicesDropdownOpen[deviceItemIndex] = !devicesDropdownOpen[deviceItemIndex]; keyUpdate++; }"
                                >
                                    <span :title="item.name">
                                        {{ item.name }}
                                    </span>
                                    <span class="flex items-center" style="font-size: 14px;" v-if="devicesConfigs.find(itemF2 => itemF2.deviceID === item.id)">
                                        <DeviceIcon 
                                            :category="devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved" style="margin-right: 15px;" /> 

                                            {{ devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved }}
                                    </span>

                                    <div class="parent_product_down_arrow">
                                        <img src="@/assets/img/Arrow-down.svg" alt="">
                                    </div>
                                </div>
                                <div 
                                    class="chart__body-wrapper" v-if="devicesDropdownOpen[deviceItemIndex] && realDeviceData.find(itemD => itemD.device === item.id)" :key="item.id+'-device-chart'"
                                    style="border: 1px solid #DDE2E4;border-radius: 7px;margin-top: 10px;padding: 10px;max-height: initial;"
                                >
                                    <div 
                                        class="card flex flex-col flex-auto" style="margin: 10px 0;"
                                        v-if="devicesConfigs.find(itemF => itemF.deviceID === item.id) && devicesConfigs.find(itemF => itemF.deviceID === item.id).atDayCreation && devicesFromConfigsData.find(itemF => itemF.deviceID === item.id) && devicesFromConfigsData.find(itemF => itemF.deviceID === item.id).atDayCreation"
                                    >
                                        <div class="card__header style-2">{{ devCats.find(itemF => itemF.id === devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved).name }} {{ $translate('at-the-day-of-product-creation') }}:</div>
                                        <div class="card__body style-2 flex-auto flex items-center justify-center">
                                            <div class="card__body-sm flex flex-col items-center">
                                                <div class="actual-info flex flex-col items-center">
                                                    <DeviceIcon :category="devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved" class="background-color-transparent" />
                                                    <span>
                                                        {{ devicesFromConfigsData.find(itemF => itemF.deviceID === item.id).atDayCreation }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        class="card flex flex-col flex-auto" style="margin: 10px 0;"
                                        v-if="devicesConfigs.find(itemF => itemF.deviceID === item.id) && devicesConfigs.find(itemF => itemF.deviceID === item.id).duringDayCreation && devicesFromConfigsData.find(itemF => itemF.deviceID === item.id) && devicesFromConfigsData.find(itemF => itemF.deviceID === item.id).duringDayCreation"
                                    >
                                        <div class="card__header style-2">
                                            {{ devCats.find(itemF => itemF.id === devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved).name }} {{ $translate('during-the-day/week/month-of-the-product-creation') }}:
                                        </div>
                                        <div class="card__body style-2 flex-auto flex items-center justify-center">
                                            <div class="card__body-sm flex flex-col items-center">
                                                <div class="actual-info flex flex-col items-center">
                                                    <DeviceIcon :category="devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved" class="background-color-transparent" />
                                                    <span>
                                                        {{ devicesFromConfigsData.find(itemF => itemF.deviceID === item.id).duringDayCreation }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        class="card flex flex-col flex-auto" style="margin: 10px 0;"
                                        v-if="devicesConfigs.find(itemF => itemF.deviceID === item.id) && devicesConfigs.find(itemF => itemF.deviceID === item.id).duringSmartCondition && devicesFromConfigsData.find(itemF => itemF.deviceID === item.id) && devicesFromConfigsData.find(itemF => itemF.deviceID === item.id).duringSmartCondition"
                                    >
                                        <div class="card__header style-2">
                                            {{ devCats.find(itemF => itemF.id === devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved).name }} {{ $translate('during-the-smart-condition-in-the-dispatch') }}
                                        </div>
                                        <div class="card__body style-2 flex-auto flex items-center justify-center">
                                            <div class="card__body-sm flex flex-col items-center">
                                                <div class="actual-info flex flex-col items-center">
                                                    <DeviceIcon :category="devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved" class="background-color-transparent" />
                                                    <span>
                                                        {{ devicesFromConfigsData.find(itemF => itemF.deviceID === item.id).duringSmartCondition }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- @setCurrent="dataFromChart = $event" -->
                                    <div v-if="devicesConfigs.find(itemF => itemF.deviceID === item.id) && devicesConfigs.find(itemF => itemF.deviceID === item.id).maxMinValue.checked && devicesFromConfigsData.find(itemF => itemF.deviceID === item.id) && devicesFromConfigsData.find(itemF => itemF.deviceID === item.id).maxMinValue">
                                        <div class="card__header style-2" style="color: #9DB0B7;padding: 0 0 10px;margin: 0 0 10px;">
                                            {{ $translate('max/min-value-of-the') }} <span style="text-transform: lowercase;">{{ devCats.find(itemF => itemF.id === devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved).name }}</span> {{ $translate('during-a-time-range') }}:
                                        </div>
                                        <MeasurmentsChart 
                                            :chartData="devicesFromConfigsData.find(itemF => itemF.deviceID === item.id).maxMinValue"
                                            :category="item.category" :dateSort="dateSort" style="padding: 0;border: 0;border-width: 0;"
                                            :chartId="`chart-${item.id}`" :dataFontSize="10"
                                        />
                                    </div>
                                    <div 
                                        class="card flex flex-col flex-auto" style="margin: 10px 0;"
                                        v-if="devicesConfigs.find(itemF => itemF.deviceID === item.id) && devicesConfigs.find(itemF => itemF.deviceID === item.id).exactValue.checked && devicesFromConfigsData.find(itemF => itemF.deviceID === item.id) && devicesFromConfigsData.find(itemF => itemF.deviceID === item.id).exactValue"
                                    >
                                        <div class="card__header style-2">
                                            {{ $translate('exact-value-of-the') }} <span style="text-transform: lowercase;">{{ devCats.find(itemF => itemF.id === devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved).name }}</span> {{ $translate('during-a-time-range') }}
                                        </div>
                                        <div class="card__body style-2 flex-auto flex items-center justify-center">
                                            <div class="card__body-sm flex flex-col items-center">
                                                <div class="actual-info flex flex-col items-center">
                                                    <DeviceIcon :category="devicesConfigs.find(itemF2 => itemF2.deviceID === item.id).parameterObserved" class="background-color-transparent" />
                                                    <span>
                                                        {{ devicesFromConfigsData.find(itemF => itemF.deviceID === item.id).exactValue }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div v-if="product.qr_settings.parent_products && parentProducts.length">
                            <div class="mobile__list-title mt-15">{{ $translate('parent-products') }}:</div>
                            <template v-for="(item, parentPIndex) in parentProducts">
                                <div 
                                    class="parent_product_box mt-15" :key="item.id" :class="{ 'open':parentProductsDropdown[parentPIndex] }" 
                                    @click="()=> { parentProductsDropdown[parentPIndex] = !parentProductsDropdown[parentPIndex]; keyUpdate++; }"
                                >
                                    <span>{{ item.title || '-' }}</span>
                                    <!-- <span>{{ product.parent_products.find(itemF => itemF.id === item.id).type }}</span> -->
                                    <span>{{ product.batch_id }}</span>

                                    <div class="parent_product_down_arrow">
                                        <img src="@/assets/img/Arrow-down.svg" alt="">
                                    </div>
                                </div>
                                <div 
                                    :key="item.id+'-dropdown'" v-if="parentProductsDropdown[parentPIndex]"
                                    style="padding: 20px;border: 1px solid #DDE2E4;border-radius: 8px;margin-top: 10px;"
                                >
                                    <div v-if="parentProductMedia.length">
                                        <img class="mobile__img" :src="image.url" alt="Product" v-for="image in parentProductMedia[parentPIndex]" :key="image.id+'-images'">
                                    </div>
                                    <h2 class="mobile__title">{{ item.title || '-' }}</h2>
                                    <ul class="mobile__list">
                                        <li class="mobile__list-item">
                                            <div class="mobile__list-title">{{ $translate('quantity/units') }}:</div>
                                            <div class="mobile__list-description">
                                                {{ item.quantity || '-' }}/{{ item.units || '-' }}
                                            </div>
                                        </li>
                                        <li class="mobile__list-item" v-if="company.company_name">
                                            <div class="mobile__list-title">{{ $translate('manufacturer') }}:</div>
                                            <div class="mobile__list-description">
                                                {{ company.company_name }}
                                            </div>
                                        </li>
                                        <li class="mobile__list-item" v-if="item.batch_id">
                                            <div class="mobile__list-title">{{ $translate('batch-id') }}:</div>
                                            <div class="mobile__list-description">
                                                {{ item.batch_id }}
                                            </div>
                                        </li>
                                        <li class="mobile__list-item" v-if="item.description">
                                            <div class="mobile__list-title">{{ $translate('description') }}:</div>
                                            <div class="mobile__list-description">
                                                {{ item.description }}
                                            </div>
                                        </li>
                                        <li class="mobile__list-item" v-if="item.manufacturing_date">
                                            <div class="mobile__list-title">{{ $translate('manufacturing-date') }}:</div>
                                            <div class="mobile__list-description">
                                                {{ item.manufacturing_date || "-" }}
                                            </div>
                                        </li>
                                        <li class="mobile__list-item" v-if="item.expiry_date && !isSpecial">
                                            <div class="mobile__list-title">{{ $translate('expiry-date') }}:</div>
                                            <div class="mobile__list-description">
                                                {{ item.expiry_date || "-" }}
                                            </div>
                                        </li>
                                        <li class="mobile__list-item" v-if="item.location && !isSpecial">
                                            <div class="mobile__list-title">{{ $translate('place-of-origin') }}:</div>
                                            <div class="mobile__list-description">
                                                {{ item.location && item.location.address ? item.location.address : "-" }}
                                            </div>
                                        </li>
                                        <li class="mobile__list-item" v-if="isSpecial">
                                            <div class="mobile__list-title">{{ $translate('place-of-origin') }}:</div>
                                            <div class="mobile__list-description">
                                                Kuddia Randazzo Lampedusa, Italy
                                            </div>
                                        </li>
                                        <!-- <li class="mobile__list-item" v-if="!isSpecial">
                                            <div class="mobile__list-title">{{ $translate('device-data') }}:</div>
                                            <div class="mobile__list-description">
                                                {{ item.devices.items[0] ? item.devices.items[0].name : '-' }}
                                            </div>
                                        </li> -->
                                        <li class="mobile__list-item" v-if="item.serial_number">
                                            <div class="mobile__list-title">{{ $translate('serial') }}:</div>
                                            <div class="mobile__list-description">
                                                {{ item.serial_number || '-' }}
                                            </div>
                                        </li>
                                        <li class="mobile__list-item" v-if="false">
                                            <div class="mobile__list-title">{{ $translate('sold-by') }}:</div>
                                            <div class="mobile__list-description">
                                                01.02.2021
                                            </div>
                                        </li>
                                        <li class="mobile__list-item">
                                            <div class="mobile__list-title">Type of event:</div>
                                            <div class="mobile__list-description" style="text-transform: capitalize;">
                                                {{ product.parent_products.find(ppItem => ppItem.id === item.id).type }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div id="history" class="tab-content__item" :class="isActiveTab('history')">
                        <ProductHistory
                            :productID="productID"
                            :companyID="companyID"
                            :product="product"
                            :company="company"
                            :dispatchReceiveDate="dispatchReceiveDate"
                        />
                    </div>
                    <div id="company" class="tab-content__item" :class="isActiveTab('company')">
                        <!-- <img class="mobile__img" src="@/assets/img/product-info.jpg" alt="Product"> -->
                        <div v-if="companyPhoto.length">
                            <img class="mobile__company-img" :src="img.url" v-for="(img, imgIndex) in companyPhoto" :key="img.url+'-companyPhoto-'+imgIndex">
                        </div>
                        <h2 class="mobile__title" style="margin: 15px 0;">{{ company.company_name }}</h2>
                        <a 
                            :href="!company.website.includes('http') ? 'http://'+company.website : company.website" target="_blank" v-if="company.website"
                            style="color: var(--text-primary);text-align: center;display: block;margin: 0px 0 15px;"
                        >
                            {{ company.website }}
                        </a>
                        <div class="mobile__desription" v-if="config.includes('history_of_company')">{{ company.history_of_company || '-' }}</div>
                        <ul class="mobile__list-company">
                            <li class="mobile__list-item"  v-for="c in Object.entries(companyConfig).filter(entry=>config.includes(entry[0]))" :key="c[0]">
                                <div class="mobile__list-title">
                                    {{ c[1] }}:
                                </div>
                                <p class="mobile__list-description">
                                    {{ company[c[0]] || '-' }}
                                </p>
                            </li>
                            <li class="mobile__list-item" v-if="company.city && company.country && product.qr_settings.location">
                                <div class="mobile__list-title">{{ $translate('location') }}</div>
                                <p class="mobile__list-description">{{ `${company.city}, ${company.country}` }}</p>
                            </li>
                            <!-- <li class="mobile__list-item" v-if="company.website">
                                <div class="mobile__list-title">Website</div>
                                <p class="mobile__list-description">{{ company.website || '-' }}</p>
                            </li> -->
                            <li class="mobile__list-item" v-if="company.vat && !isSpecial">
                                <div class="mobile__list-title">{{ $translate('vat-number') }}</div>
                                <p class="mobile__list-description">{{ company.vat || '-' }}</p>
                            </li>
                        </ul>

                        <template v-if="certificates.length && product.qr_settings.certifications">
                            <div class="mobile__list-title mb-15">{{ $translate('certifications') }}:</div>
                            <!-- <div>
                                <img class="mobile__company-img" :src="img.url" v-for="(img, imgIndex) in certificates" :key="img.url+'-certificates-'+imgIndex">
                            </div> -->
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="c in certificates" :key="c.id">
                                    <div class="data-row">
                                        <ul class="data-row__list attachment-list">
                                            <li class="data-row__item attachment-list-title" style="max-width: 100%;">
                                                <inline-svg :src="require(`@/assets/img/document-file-table.svg`)"/>
                                                <span style="margin-left: 10px;overflow: initial;white-space: initial;word-break: break-word;">
                                                    {{ c.title }}
                                                </span>
                                            </li>
                                            <li class="data-row__item">
                                                <a target="_blank" :href="c.url" class="btn btn-primary btn-sm">{{ $translate('download') }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </template>
                        <template v-if="documents.length && product.qr_settings.documentation">
                            <div class="mobile__list-title mt-15 mb-15">{{ $translate('documentation') }}:</div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="doc in documents" :key="doc.id">
                                    <div class="data-row">
                                        <ul class="data-row__list attachment-list">
                                            <li class="data-row__item attachment-list-title">
                                                <inline-svg :src="require(`@/assets/img/Document.svg`)"/>
                                                <span style="margin-left: 10px;overflow: initial;white-space: initial;word-break: break-word;">
                                                    {{ doc.title }}
                                                </span>
                                            </li>
                                            <li class="data-row__item">
                                                <a target="_blank" :href="doc.url" class="btn btn-primary btn-sm">{{ $translate('download') }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </template>
                        <template  v-if="(companyMediaImg.length || companyMediaVideos.length) && product.qr_settings.media">
                            <div class="mobile__list-title mt-15 mb-15">{{ $translate('media') }}:</div>
                            <div>
                                <img class="mobile__company-img" :src="img.url" v-for="(img, imgIndex) in companyMediaImg" :key="img.url+'-media-img-'+imgIndex">
                                <div class="mobile__company-img" v-for="(video, videoIndex) in companyMediaVideos" :key="video.url+'-media-video-'+videoIndex" style="overflow: hidden;">
                                    <video width="100%" height="100%" controls="controls" style="background: #e4e4e4;" :class="'video-'+video.id">
                                        <source :src="video.url">
                                    </video>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
             
            </main>
        </div>
        <div class="mobile__footer">
            <button 
                class="btn btn-w-shadow btn-standart-2" @click="switchTab(item.value)"
                :class="isActiveTab(item.value)" v-for="(item, index) in tabsFullList" :key="index"
            >
                {{ item.value }}
            </button>
        </div>
    </div>
</template>

<script>
import { getProduct,getCompany,getCategory } from "@/graphql/queries";
import { API, Storage } from "aws-amplify";
import qrSettings from "@/config/qrSettings"
import ProductHistory from "@/views/QRMobile/ProductHistory.vue"
import Base from "@/components/base";
import MeasurmentsChart from "@/components/charts/MeasurmentsChart";
import { devCats } from '@/config';

export default {
    name:"QRMobile",
    props:[],
	components: {
        ProductHistory,
        ...Base,
        MeasurmentsChart,
	},
	data() {
		return {
            loading: true,
			pageTabs: ['product', 'history', 'company'],
            tabsFullList: [
                {
                    title: 'Product',
                    value: 'product'
                },
                {
                    title: 'Product History',
                    value: 'history'
                },
                {
                    title: 'Company',
                    value: 'company'
                },
            ],
            activeTab: 'product',
            product: null,
            company: {},
            productID: null,
            companyID: null,
            category: {},
            config: [],
            companyConfig: {
                'year_of_foundation': 'Year of foundation',
                'production_per_year': 'Production/Year',
                'production_area': 'Production area',
                'soils': 'Soils',
                //'cellar_work': 'Cellar work',
                //'productivity': 'Productivity',
                'philosophy': "Philosophy",
                // 'location': "Location",
                // 'website': "Website",
                // 'vat': "VAT Number",
            },
            productMedia: [],
            companyMediaImg: [],
            companyMediaVideos: [],
            companyPhoto: [],
            documents: [],
            certificates: [],
            parentProducts: [],
            parentProductsDropdown: [],
            keyUpdate: 0,
            devicesKeyUpdate: 0,
            parentProductMedia: [],

            realDeviceData: [],
            centaurDeviceInfo: [],
            dateSort: "Month",
            chartDateViews: [ "Day", "Month", "Year", ],
            devicesDropdownOpen: [],
            devCats: devCats,
            devicesConfigs: [],
            devicesFromConfigsData: [],
            mediaFormats: {
				images: ['.jpg', '.jpeg', '.png', '.svg'],
				videos: ['.mp4', '.avi', '.wmv', '.webm'],
			},

            deviceDataInterval: null,
            refreshData: null,
            processing: false,
            locationFromPaddock: null,
		}
	},
	computed: {
        cat() {
            if(
                !this.product ||
                !this.product.category ||
                !this.product.category.items.length ||
                !this.product.category.items[0].category
            ) return

            return this.product.category.items[0].category.name
        },
        isSpecial() {
            return this.cat && this.cat.toLowerCase() === 'giandomenico'
        },
        isParentProduct(){
            return false
        },
        headerTitle(){
            switch (this.activeTab) {
                case 'history':
                    return 'Product history';
                case 'company':
                    return 'Company';
                default:
                    return 'Enismaro - food&wine traceability';
            }
        },
        // product(){
        //     let id=this.$route.query["product-id"]
        //     return this.$store.state.products.find(p=>p.id==id) || {}
        // },
        // category(){
        //     return this.$store.state.categories.find(c=>c.id==this.product?.category?.items[0]?.categoryID) || {}
        // },
        device(){
            return this.product?.devices?.items[0] || {}
        },
        dispatchReceiveDate(){
            let findedDispatchWithProduct = [];
            
            if(this.$store.state.dispatches){
                this.$store.state.dispatches.forEach(item => {
                    let itemForMap = { ...item };
                    if(itemForMap.companiesStatus.map(itemRD => itemRD.receive_date).map(itemS => itemS !== null).includes(true)){
                        item.batches.map(item2 => item2.products).forEach(item2 => {
                            if(item2.includes(this.productID)){
                                findedDispatchWithProduct.push(item);
                            }
                        })
                    }
                })
            }

            let findDispatch = findedDispatchWithProduct.find(item => this.product.dispatches.includes(item.id));

            if(findDispatch){
                return findDispatch.companiesStatus.find(item => item.receive_date)?.receive_date;
            } else {
                return null;
            }
        },
        paddockList(){
            return this.$store.state.paddocks;
        },
	},
	methods: {
        switchTab(tab){
            this.activeTab = tab
        },
        isActiveTab(tab){
            return tab === this.activeTab ? "active" : null
        },
        async getProduct(id){
            const product = await API.graphql({
                query: getProduct,
                variables: {
                    id: id,
                },
            }).then((res) => res.data.getProduct);
            
            this.product = product;

            if(this.product?.parent_products && this.product?.parent_products?.length){
                let requests = this.product.parent_products.map(async element => {
                    this.parentProductsDropdown.push(false);
					return await API.graphql({
                        query: getProduct,
                        variables: {
                            id: element.id,
                        },
                    }).then((res) => {
                        return res.data.getProduct;
                    });
				});

                Promise.all(requests).then(async responses => {
                    this.parentProducts = responses;
                    await this.getAttachmentsParentProduct();
                });
            }

            let self = this;

            this.refreshData = function(){
                self.realDeviceData = [];
                self.centaurDeviceInfo = [];
                self.devicesDropdownOpen = [];
                self.devicesConfigs = [];
                self.devicesFromConfigsData = [];

                if(self.product && self.product.devices && self.product.devices.items.length) {
                    self.product.devices.items.forEach(async element => {
                        if(element.config.device_id){
                            let getedRealDeviceData = await self.$provider.centaurAPI.getRealDeviceData(element.config.device_id);
                            let getedCentaurDeviceInfo = await self.$provider.centaurAPI.getDevicesInfo(element.config.device_id);

                            self.realDeviceData.push({
                                device: element.id,
                                data: getedRealDeviceData,
                            });

                            self.centaurDeviceInfo.push({
                                device: element.id,
                                data: getedCentaurDeviceInfo
                            });

                            self.devicesDropdownOpen.push(false);
                        }
                    });
                }

                if(self.product && self.product.qr_settings.devices.length){
                    self.product.qr_settings.devices.forEach(async item => {
                        let parsedConfig = JSON.parse(item.config);
                        self.devicesConfigs.push(parsedConfig);

                        let dataToFromConfigsData = {
                            deviceID: parsedConfig.deviceID,
                            atDayCreation: null,
                            duringDayCreation: null,
                            duringSmartCondition: null,
                            maxMinValue: null,
                            exactValue: null,
                        };

                        let findDeviceItem = self.product.devices.items.find(itemD => itemD.id === parsedConfig.deviceID);

                        if(parsedConfig.atDayCreation){
                            if(findDeviceItem.category === 'carbon-dioxide' || findDeviceItem.category === 'phosphine') {
                                const getedRealDeviceDataThere = await self.$provider.centaurAPI.getRealDeviceData(
                                    findDeviceItem.config.device_id, { from: self.product.createdAt.split('T')[0]+'T00:01:00.000Z' }
                                );

                                if(getedRealDeviceDataThere && getedRealDeviceDataThere.length){
                                    dataToFromConfigsData.atDayCreation = getedRealDeviceDataThere[0].value;
                                } else {
                                    dataToFromConfigsData.atDayCreation = false;
                                }
                            } else {
                                const getedRealDeviceDataThere = await self.$provider.xFarm.getDeviceData(
                                    findDeviceItem.config.device_id, 
                                    { from: self.product.createdAt.split('T')[0]+'T00:01:00.000Z', periodTo: '1d' }
                                );

                                let valueVariableName = devCats.find(item => item.id === parsedConfig.parameterObserved).xFarmVN;

                                if(getedRealDeviceDataThere && getedRealDeviceDataThere.length && getedRealDeviceDataThere[0][valueVariableName]){
                                    dataToFromConfigsData.atDayCreation = getedRealDeviceDataThere[getedRealDeviceDataThere.length-1][valueVariableName];
                                } else {
                                    dataToFromConfigsData.atDayCreation = false;
                                }
                            }
                        }

                        if(parsedConfig.duringDayCreation){
                            if(findDeviceItem.category === 'carbon-dioxide' || findDeviceItem.category === 'phosphine') {
                                const getedRealDeviceDataThere = await self.$provider.centaurAPI.getRealDeviceData(
                                    findDeviceItem.config.device_id, { from: self.product.createdAt.split('T')[0]+'T00:01:00.000Z', to: self.product.createdAt.split('T')[0]+'T23:59:59.000Z' }
                                );

                                if(getedRealDeviceDataThere && getedRealDeviceDataThere.length){
                                    dataToFromConfigsData.duringDayCreation = `${getedRealDeviceDataThere[0].value}-${getedRealDeviceDataThere[getedRealDeviceDataThere.length-1].value}`;
                                } else {
                                    dataToFromConfigsData.duringDayCreation = false;
                                }
                            } else {
                                const getedRealDeviceDataThere = await self.$provider.xFarm.getDeviceData(
                                    findDeviceItem.config.device_id, 
                                    { from: self.product.createdAt.split('T')[0]+'T00:01:00.000Z', periodTo: '1d' }
                                );

                                let valueVariableName = devCats.find(item => item.id === parsedConfig.parameterObserved).xFarmVN;

                                if(getedRealDeviceDataThere && getedRealDeviceDataThere.length && getedRealDeviceDataThere[0][valueVariableName]){
                                    dataToFromConfigsData.duringDayCreation = `${getedRealDeviceDataThere[0][valueVariableName]}-${getedRealDeviceDataThere[getedRealDeviceDataThere.length-1][valueVariableName]}`;
                                } else {
                                    dataToFromConfigsData.duringDayCreation = false;
                                }
                            }
                        }

                        if(parsedConfig.duringSmartCondition && self.dispatchReceiveDate){
                            if(findDeviceItem.category === 'carbon-dioxide' || findDeviceItem.category === 'phosphine') {
                                const getedRealDeviceDataThere = await self.$provider.centaurAPI.getRealDeviceData(
                                    findDeviceItem.config.device_id, { from: new Date(+self.dispatchReceiveDate).toISOString() }
                                );

                                if(getedRealDeviceDataThere && getedRealDeviceDataThere.length){
                                    dataToFromConfigsData.duringSmartCondition = `${ getedRealDeviceDataThere[0].value }`;
                                } else {
                                    dataToFromConfigsData.duringSmartCondition = false;
                                }
                            } else {
                                const getedRealDeviceDataThere = await self.$provider.xFarm.getDeviceData(
                                    findDeviceItem.config.device_id, 
                                    { from: new Date(+self.dispatchReceiveDate).toISOString(), periodTo: '1d' }
                                );

                                let valueVariableName = devCats.find(item => item.id === parsedConfig.parameterObserved).xFarmVN;

                                if(getedRealDeviceDataThere && getedRealDeviceDataThere.length && getedRealDeviceDataThere[getedRealDeviceDataThere.length-1][valueVariableName]){
                                    dataToFromConfigsData.duringSmartCondition = `${ getedRealDeviceDataThere[getedRealDeviceDataThere.length-1][valueVariableName] }`;
                                } else {
                                    dataToFromConfigsData.duringSmartCondition = false;
                                }
                            }
                        }

                        if(parsedConfig.maxMinValue.checked){
                            let splitMinDate = parsedConfig.maxMinValue.minDate.split('.');
                            let splitMaxDate = parsedConfig.maxMinValue.maxDate.split('.');

                            if(findDeviceItem.category === 'carbon-dioxide' || findDeviceItem.category === 'phosphine') {
                                const getedRealDeviceDataThere = await self.$provider.centaurAPI.getRealDeviceData(
                                    self.product.devices.items.find(
                                        itemD => itemD.id === parsedConfig.deviceID
                                    ).config.device_id, {
                                        // 2022-03-16T15:56:48.520Z
                                        from: `${ splitMinDate[2]+'-'+splitMinDate[1]+'-'+splitMinDate[0] }T${parsedConfig.maxMinValue.minTime}:00.000Z`, 
                                        to: `${ splitMaxDate[2]+'-'+splitMaxDate[1]+'-'+splitMaxDate[0] }T${parsedConfig.maxMinValue.maxTime}:00.000Z` 
                                    }
                                );
                                
                                if(getedRealDeviceDataThere && getedRealDeviceDataThere.length){
                                    dataToFromConfigsData.maxMinValue = getedRealDeviceDataThere;
                                } else {
                                    dataToFromConfigsData.maxMinValue = false;
                                }
                            } else {
                                let timeDiff = Math.abs(new Date(`${ splitMinDate[2]+'-'+splitMinDate[1]+'-'+splitMinDate[0] }T${parsedConfig.maxMinValue.minTime}:00.000Z`).getTime() - new Date(`${ splitMaxDate[2]+'-'+splitMaxDate[1]+'-'+splitMaxDate[0] }T${parsedConfig.maxMinValue.maxTime}:00.000Z`).getTime());
                                let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                
                                let getedRealDeviceDataThere = await self.$provider.xFarm.getDeviceData(
                                    findDeviceItem.config.device_id, 
                                    { from: new Date(+self.dispatchReceiveDate).toISOString(), periodTo: `${ diffDays }d`}
                                );

                                getedRealDeviceDataThere = getedRealDeviceDataThere.filter(item => new Date(item.time).getTime() > new Date(`${ splitMaxDate[2]+'-'+splitMaxDate[1]+'-'+splitMaxDate[0] }T${parsedConfig.maxMinValue.maxTime}:00.000Z`).getTime());

                                let valueVariableName = devCats.find(item => item.id === parsedConfig.parameterObserved).xFarmVN;

                                if(getedRealDeviceDataThere && getedRealDeviceDataThere.length){
                                    dataToFromConfigsData.maxMinValue = getedRealDeviceDataThere.map(item => {
                                        return {
                                            "timestamp": item.time,
                                            "type": 'temp',
                                            "value": item[valueVariableName],
                                            "deviceId": item.did,
                                        }
                                    });
                                } else {
                                    dataToFromConfigsData.maxMinValue = false;
                                }
                            }
                        }
                        
                        if(parsedConfig.exactValue.checked){
                            let splitMinDate = parsedConfig.exactValue.firstDate.split('.');
                            
                            if(findDeviceItem.category === 'carbon-dioxide' || findDeviceItem.category === 'phosphine') {
                                const getedRealDeviceDataThere = await self.$provider.centaurAPI.getRealDeviceData(
                                    self.product.devices.items.find(
                                        itemD => itemD.id === parsedConfig.deviceID
                                    ).config.device_id, {
                                        from: `${ splitMinDate[2]+'-'+splitMinDate[1]+'-'+splitMinDate[0] }T${parsedConfig.exactValue.firstTime}:00.000Z`,
                                    }
                                );
                                
                                if(getedRealDeviceDataThere && getedRealDeviceDataThere.length){
                                    dataToFromConfigsData.exactValue = getedRealDeviceDataThere[0].value;
                                } else {
                                    dataToFromConfigsData.exactValue = false;
                                }
                            } else {
                                const getedRealDeviceDataThere = await self.$provider.xFarm.getDeviceData(
                                    findDeviceItem.config.device_id, 
                                    { from: `${ splitMinDate[2]+'-'+splitMinDate[1]+'-'+splitMinDate[0] }T${parsedConfig.exactValue.firstTime}:00.000Z`, periodTo: '1d' }
                                );

                                let valueVariableName = devCats.find(item => item.id === parsedConfig.parameterObserved).xFarmVN;

                                if(getedRealDeviceDataThere && getedRealDeviceDataThere.length && getedRealDeviceDataThere[getedRealDeviceDataThere.length-1][valueVariableName]){
                                    dataToFromConfigsData.exactValue = `${ getedRealDeviceDataThere[getedRealDeviceDataThere.length-1][valueVariableName] }`;
                                } else {
                                    dataToFromConfigsData.exactValue = false;
                                }
                            }
                        }

                        self.devicesFromConfigsData.push(dataToFromConfigsData);
                    })
                }

                self.devicesKeyUpdate++;
            }

            this.refreshData();

            this.deviceDataInterval = setInterval(() => {
                self.refreshData();
                self.devicesKeyUpdate++;
            }, 60000);
        },
        async getCompany(id){
            const company = await API.graphql({
                query: getCompany,
                variables: {
                    id: id,
                },
            }).then((res) => res.data.getCompany);

            this.getAttachments(company);

            company.location = company.lat_lng?.address || '-';
            this.company = company;
        },
        async getCategory(id){
            let categoryID = this.product?.category?.items[0]
            return 
            if(!categoryID){
                return
            }
            const category = await API.graphql({
                query: getCategory,
                variables: {
                    id: categoryID,
                },
            }).then((res) => res.data.getCategory);
            this.category=category
        },
		async getAttachments(company) {
			let requests, requests2, documentRequests, certificatesRequests;

            if(company && company.company_photo && company.company_photo.length) {
				requests2 = company.company_photo.map(async item => { 
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key) 
					}	
				});
				Promise.all(requests2).then(responses => this.companyPhoto = responses);
			}

			if(company && company.media && company.media.items) {
                let mediaResponses = [];
				requests = company.media.items.filter(item => !item.key.includes('company_photo')).map(async item => { 
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key) 
					}	
				});
				Promise.all(requests).then(responses => mediaResponses = responses);

                setTimeout(() => {
                    mediaResponses.forEach(item => {
                        this.mediaFormats.images.forEach(item2 => {
                            if(item.key.includes(item2)){
                                this.companyMediaImg.push(item);
                            }
                        })
                        this.mediaFormats.videos.forEach(item2 => {
                            if(item.key.includes(item2)){
                                this.companyMediaVideos.push(item);
                            }
                        })
                    })
                }, 2000);
			}

            if(company && company.documentation && company.documentation.items) {
				documentRequests = company.documentation.items.map(async item => {
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key),
						title: item.title 
					}
				})

				Promise.all(documentRequests).then(responses => this.documents = responses);
			}

            if(company && company.certifications && company.certifications.items) {
				certificatesRequests = company.certifications.items.map(async item => {
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key),
						title: item.title 
					}
				})

				Promise.all(certificatesRequests).then(responses => this.certificates = responses);
			}
		},
        async getAttachmentsProduct() {
			if(this.product && this.product.media && this.product.media.items) {
				let requests = this.product.media.items.map(async item => { 
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key) 
					}	
				});
				Promise.all(requests).then(responses => this.productMedia = responses);
			}			
		},
        async getAttachmentsParentProduct() {
            let localParentPData = Object.assign([], this.parentProducts);

            if(localParentPData.length){
                localParentPData.forEach(item => {
                    if(item.media && item.media.items) {
                        let requests = item.media.items.map(async item2 => { 
                            return {
                                id: item2.id,
                                key: item2.key,
                                url: await Storage.get(item2.key) 
                            }	
                        });
                        Promise.all(requests).then(responses => {
                            this.parentProductMedia.push(responses)
                        });
                    }
                })
            }
		},
        async initFarm(){
            if(this.$store.state.companyData && this.$store.state.companyData.id){
                let farm = await this.$store.dispatch('GET_FARM');
                if(farm){
                    await this.$store.dispatch('GET_PADDOCKS');
                }
                
            } else {
                setTimeout(() => {
                    this.initFarm();
                }, 1000);
            }
        },
        async getLocationFromPaddock(){
            if(!this.product?.location && !this.product?.location.address && this.product?.paddocks && this.product?.paddocks.length){
                // 
                let location = {
                    lat: null,
                    lng: null,
                };
                let devicePaddok = null;

                if(this.product.paddocks && this.product.paddocks.length){
                    devicePaddok = this.paddockList.find(item => item.id === this.product.paddocks[0]);
                }

                if(devicePaddok){
                    if(devicePaddok.map_info.center_lat && devicePaddok.map_info.center_lng){
                        location.lat = devicePaddok.map_info.center_lat;
                        location.lng = devicePaddok.map_info.center_lng;
                    }

                    if(devicePaddok.map_info.points && devicePaddok.map_info.points.length){
                        let latValue = 0;
                        let lngValue = 0;

                        for (let index = 0; index < devicePaddok.map_info.points.length; index++) {
                            latValue += devicePaddok.map_info.points[index].lat;
                            lngValue += devicePaddok.map_info.points[index].lng;                        
                        }
                        latValue = latValue / devicePaddok.map_info.points.length;
                        lngValue = lngValue / devicePaddok.map_info.points.length;

                        location.lat = latValue;
                        location.lng = lngValue;
                    }
                }

                let query = Object.values(location).join();
                const { data } = await this.$provider.positionStack.getReverse(query);

                if(data.length) {
                    const {
                        country,
                        locality
                    } = data[0];

                    this.locationFromPaddock = {
                        ...location,
                        address:country+", "+locality
                    }
                }
            }
        }
	},
    async created(){
        this.productID = this.$router.history.current.query.productID;
        this.companyID = this.$router.history.current.query.companyID;

        await this.getProduct(this.productID);
        await this.getAttachmentsProduct();

        await this.getCompany(this.companyID);
        await this.getCategory();
        await this.initFarm();

        this.config=Object.keys(this.$route.query).map(param=>{
            if(param=="address"){
                return 'location'
            }else{
                return param
            }
        }).filter(param=>{
            // let transformParam=param=="address"?'location':param
            return qrSettings.includes(param);
        });

        await this.getLocationFromPaddock();

        this.loading = false;
    },
    beforeDestroy(){
        let self = this;
        clearInterval(self.refreshData);
    }
}
</script>


<style scoped>
.parent_product_box {
    display: flex;
    align-items: center;
    border: 1px solid #DDE2E4;
    border-radius: 7px;
    padding: 15px 20px;
    white-space: nowrap;
    cursor: pointer;
}
.parent_product_box:hover {
    opacity: 0.7;
}
.parent_product_box.less_paddings {
    padding: 7px 20px;
}
.parent_product_box span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #00283A;
}
.parent_product_box span:first-child {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.parent_product_box span+span {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #DDE2E4;
    text-transform: capitalize;
}
.mobile-content__header{
    padding: 16px 0;
    border-bottom: 1px solid var(--border-primary);
    position: fixed;
    top: 0;
    left: 14px;
    right: 0;
    z-index: 100;
    background-color: white;
    width: calc(100% - 28px);
    height: 70px;
    display: flex;
    align-items: center;
}
.mobile-logo__img{
    margin-right: 20px;   
}
.mobile-wrapper__main{
    padding-top: 77px;
    padding-bottom: 70px;
}
.mobile-content__body{
    margin: 0 auto;
    padding: 20px 15px 35px;
    max-width: 800px;
}
.mobile__img{
    width: 100%;
    border-radius: 7px
}
.mobile__img+.mobile__img {
    margin-top: 15px;
}
.mobile__title {
    font-weight: bold;
    font-size: 21px;
    text-align: center;
    margin: 30px 0;
}
.mobile__list-company {
    margin: 25px 0;
    /* padding: 25px 0; */
    padding-top: 25px;
    border-top: 1px solid var(--border-primary);
    border-bottom: 1px solid var(--border-primary);
}
.mobile__list-item {
    display: flex;
    border: 1px solid var(--border-primary);
    border-collapse: collapse;
    border-bottom: 0;
}
.mobile__list .mobile__list-item:last-child {
    border-bottom: 1px solid var(--border-primary);
}
.mobile__list-title {
    color: var(--text-secondery);
    width: calc(50% - 10px);
    margin-right: 10px;
}
.mobile__list-item .mobile__list-title {
    border-right: 1px solid var(--border-primary);
    padding: 7px;
}
.mobile__list-description {
    width: calc(50% - 10px);
    padding: 7px 0;
}
.mobile__list-description.accent {
    color:var(--accent-primary);
}
/* .mobile__list-item+.mobile__list-item {
    margin-top: 15px;
} */
.mobile__footer {
    border-top: 1px solid var(--border-primary);
    padding:  15px;
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 2;
}
.mobile__footer .btn+.btn {
    margin-left: 10px;
}
.btn-standart,.btn-standart-2 {
    width: 100%;
    height: 50px;
    border: 1px solid #b3b8bb;
}
.mobile-devider {
    background-color: var(--text-secondery);
    height: 40px;
    width: 1px;
    margin: 0 auto;
}
.mobile-history__block {
    display: flex;
    justify-content: center;
}
.mobile-history__data { 
    height: 40px;
    width: 131px;
    font-size: 14px;
}
.mobile-history__data--right {
    text-align: right;
}
.mobile-history__mark-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1FBFF;
    margin: 0 12px;
    border-radius: 10px;
}
.mobile-history__mark {
    width: 17px;
    height: 18px;
}
.mt-40 {
    margin-top: 40px;
}
.mobile__company-img{
    width: 100%;
    border-radius: 7px
}
.mobile__company-img+.mobile__company-img{
    margin-top: 15px;
}
.parent_product_down_arrow {
    margin-left: auto;
    display: flex;
    transition: 0.3s all ease-in-out;
    padding-left: 10px;
}
.parent_product_down_arrow img {
    min-width: 24px;
}
.parent_product_box.open .parent_product_down_arrow {
    transform: rotate(180deg);
    padding-left: 0;
    padding-right: 10px;
}

@media (max-width: 800px){
    .mobile-content__body{
        max-width: 500px;
    }
}
</style>